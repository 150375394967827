* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.jersey-25-regular {
    font-family: "Jersey 25", serif;
    font-weight: 400;
    font-style: normal;
  }
  

.App {
    position: relative;
    width: 100%;
    height: 100vh;
    background-image: url(../public/background.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.App::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(217, 217, 217, 0.4);
    pointer-events: none;
    z-index: 0;
}

.App-container{
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
}

#wheel-container{
    width: 100%;
    aspect-ratio:  1 / 1;
    border-radius: 9999999px;
    max-height: 85vh;
    display: flex;
    justify-content: center;
    overflow: visible;
  }

  .finalMessage{
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: max-content;
    background: #3577B0;
    padding: 55px 10px;
    text-transform: uppercase;
    color: #FFFFFF;
    transition: 0.4s ease;
}
.finalMessage.active{
    bottom: 0%;
}


.finalMessage h2{
    text-align: center;
    font-size: 106px;
}
.finalMessage h3{
    text-align: center;
    font-size: 74px;
}

.campaign-button-alt {
    margin: 45px auto 0;
    display: block;
    position: relative;
    padding: 20px;
    font-size: 65px;
    background: none;
    border: none;
    text-transform: uppercase;
    outline: none;
    color: #ffffff;
    cursor: pointer;
}

.campaign-button{
    margin: 0 auto;
    display: block;
    position: relative;
    padding: 15px 30px;
    font-size: 36px;
    background: #FFFFFF;
    border: none;
    text-transform: uppercase;
    outline: none;
    color: #3577B0;
    cursor: pointer;
}


.location-login-page{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-content: center;
    align-items: center;
    height: 100%;
}

.location-login-container{
    background: #3577B0;
    padding: 25px;
}

.location-login-container h2{
    color:#FFFFFF;
    text-align: center;
    font-size: 42px;
    margin-bottom: 25px;
}

.location-login-error{
    text-align: center;
    color: #EC3034;
    margin: 0;
    font-size: 18px;
  }

.location-login-form-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    align-content: center;
    gap: 20px;
}

.location-login-form-container input{
    width: 100%;
    font-size: 30px;
    padding: 7px 9px;
    border: none;
 
    outline: none;
    font-family: 'Roboto',
    sans-serif;
    border: 1px solid #3577B0;
    text-align: center;
  }
  
  
.location-login-form-container button{
    width: 90%;
    display: block;
     margin: 0 auto;
     font-size: 34px;
     padding: 13px 9px;
     border: none;
     outline: none;
     border-radius: 7px;
     color: #3577B0;
     cursor: pointer;
     font-weight: 900;
     transition: 0.2s ease;
     background: #FFFFFF;
  }
  
  .location-login-form-container button:hover{
    background: #69BC45;
}